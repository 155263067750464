export const getEntityField = (entity, field) => {
    if (entity && typeof entity[field] === 'string') {
        if (
            field === 'shortname' &&
            entity[field].length === 0 &&
            typeof entity.name === 'string'
        ) {
            return entity.name;
        }

        return entity[field];
    }

    if (
        entity &&
        typeof entity[field] === 'object' &&
        field === 'shortname' &&
        entity[field] === null
    ) {
        if (typeof entity.name === 'string') {
            return entity.name;
        }
    }

    if (field === 'shortname') {
        if (
            entity &&
            entity['shortname'] &&
            entity['shortname']['val'] &&
            typeof entity['shortname']['val'] === 'string' &&
            entity['shortname']['val'].length > 0
        ) {
            return entity['shortname']['val'];
        }
        return entity['name']['val'];
    }

    if (entity && entity[field] && entity[field]['val']) {
        return entity[field]['val'];
    }
    return '';
};

export default getEntityField;
