export const isRedirectAccountConfirmation = (business, user) => {
    if (business && business.info && +business.info.force_login === 1) {
        if (user && (user.confirmed === 0 || user.confirmed === false)) {
            return true;
        }
    }

    return false;
};

export default isRedirectAccountConfirmation;
