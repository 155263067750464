import Router from 'next/router';
import actionsApp from '~/redux/app/actions';
import isRedirectAccountConfirmation from '~/src/lib/isRedirectAccountConfirmation';

export const handleAuth = () => {
    const state = store.getState();
    const { business, user } = state;

    //document.location.pathname

    if (isRedirectAccountConfirmation(business, user) === true) {
        Router.push('/account/confirmation');
        return false;
    }
    /*
    if (business?.info?.auth_need_authorization === 1) {
        if (user && (user.confirmed === 0 || user.confirmed === false)) {
            Router.push('/account/confirmation');
            return false;
        }
    }
    */

    if (business?.info?.auth_need_email === 1) {
        if (user.confirmed === 0 || user.confirmed === false) {
            Router.push('/account/confirmation');
            return false;
        }
    }

    if (business?.info?.force_login === 1) {
        if (user === false) {
            if (
                ['/login', '/presentation'].includes(
                    document.location.pathname,
                ) === false
            ) {
                Router.push('/presentation');
            }
        }
    }

    if (user === false) {
        if (document.location.pathname.includes('account')) {
            //console.log(665544);
            //console.log(document.location);
            store.dispatch(
                actionsApp.setRedirectAfterLogin(document.location.href),
            );
            Router.push('/login');
        }
    }

    return true;
};

export default handleAuth;
